import { ProductVariant } from "@medusajs/medusa";
import { toShowtime } from "../utils/date";
import { Product } from "../models/models";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
type VariantInfoProps = {
  variant: ProductVariant;
};

export function VariantInfo({ variant }: VariantInfoProps) {
  return getVariantDisplayTitle(variant);
}

export const getVariantShowtimeOnly = (variant: ProductVariant) => {
  return toShowtime(variant.startsAt);
};

export const getVariantsShowtimeOnly = (product: Product) => {
  return product.variants.map((v) => getVariantShowtimeOnly(v)).join(", ");
};

export const getCondensedShowtimes = (product: Product) => {
  let result = "";
  product.variants.forEach((value, index) => {
    result += toShowtime(value.startsAt, "ddd MMM D, h:mm A");
    if (index !== product.variants.length - 1) {
      result += ", ";
    }
  });
  return result;
};

export function isUtcShowtimeInFuture(date: Date): boolean {
  if (!date) return true;
  // @ts-ignore
  const threeHoursAgo = dayjs.utc().subtract(3, "h");
  // @ts-ignore
  return dayjs.utc(date).isSameOrAfter(threeHoursAgo);
}

type ConsolidatedShowTimesOptions = {
  hidePast?: boolean;
};

export const conslidatedShowtimesDisplay = (
  product: Product,
  options?: ConsolidatedShowTimesOptions,
) => {
  const result = consolidatedShowtimes(product, options);
  const showtimes_to_display = 3;
  if (result.length <= showtimes_to_display) {
    return result.join(", ");
  } else {
    return `${result.slice(0, showtimes_to_display).join(", ")} and ${result.length - showtimes_to_display} more`;
  }
};
export const consolidatedShowtimes = (
  product: Product,
  options?: ConsolidatedShowTimesOptions,
) => {
  let showtimes = product.variants.map((v) => ({
    startsAt: v.startsAt,
    startsAtUtc: v.startsAtUtc,
  }));

  if (options && options.hidePast === true) {
    // @ts-ignore
    const threeHoursAgo = dayjs.utc().subtract(3, "h");
    // @ts-ignore
    showtimes = showtimes.filter(
      (s) =>
        (s.startsAtUtc &&
          // @ts-ignore
          dayjs.utc(s.startsAtUtc).isSameOrAfter(threeHoursAgo)) ||
        !s.startsAtUtc,
    );
  }
  const result = showtimes.map((s) => toShowtime(s.startsAt)).flat();
  return [...new Set(result)];
};

export const getVariantDisplayTitle = (
  variant: ProductVariant,
  withFormatting: boolean = true,
) => {
  const showtime =
    toShowtime(variant.startsAt, "ddd MMM D, YYYY h:mm A") +
    (variant.virtual_event_timezone
      ? ` ${variant.virtual_event_timezone}`
      : "");
  const title = variant.title;
  if (!title || title.trim() === "" || title === showtime) {
    if (!variant.display_showtime) return <></>;
    return withFormatting === true ? (
      <div className={"text-md font-semibold text-jump"}>{showtime}</div>
    ) : (
      <>{showtime}</>
    );
  } else if (showtime && title) {
    return withFormatting === true ? (
      <>
        <>
          {variant.display_showtime && (
            <div className={"text-md  font-semibold text-jump"}>{showtime}</div>
          )}
        </>
        <div className={"text-sm"}>{title}</div>
      </>
    ) : (
      <>
        {variant.display_showtime && <>{showtime} / </>}
        {title}
      </>
    );
  } else {
    return <div />;
  }
};
